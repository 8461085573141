import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { Alert } from '~/components/Alert';
import { Decimals } from '~/components/Decimals';
import { StreamIdCell } from '~/components/Table';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { FormModalRoot, SectionHeadline, } from '~/modals/FormModal';
import { forceUnstakeFromSponsorship } from '~/services/sponsorships';
import { Radio } from '~/shared/components/Radio';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { isRejectionReason, isTransactionRejection } from '~/utils/exceptions';
function getOptimalStake(stakes, requestedAmount) {
    return (stakes.find((s) => isStakedLongEnough(s.joinedAt, s.minimumStakingPeriodSeconds) &&
        s.amountWei >= requestedAmount) || stakes[0]);
}
function isStakedLongEnough(joinedAt, minimumStakingPeriodSeconds) {
    return joinedAt.getTime() + minimumStakingPeriodSeconds * 1000 < Date.now();
}
function ForceUndelegateModal({ amount, onResolve, operator, chainId, ...props }) {
    const [busy, setBusy] = useState(false);
    const stakes = useMemo(() => [...operator.stakes].sort((a, b) => (b.amountWei > a.amountWei ? -1 : 1)), [operator]);
    const [selectedSponsorshipId, setSelectedSponsorshipId] = useState(getOptimalStake(stakes, amount)?.sponsorshipId);
    const selectedSponsorship = useMemo(() => stakes.find((s) => s.sponsorshipId === selectedSponsorshipId), [stakes, selectedSponsorshipId]);
    const willSlash = !!selectedSponsorship &&
        !isStakedLongEnough(selectedSponsorship.joinedAt, selectedSponsorship.minimumStakingPeriodSeconds);
    const isPartialPayout = !!selectedSponsorship && selectedSponsorship.amountWei < amount;
    const canSubmit = !!selectedSponsorshipId;
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    return (React.createElement(ForceUndelegateFormModal, { ...props, title: "Force unstake", canSubmit: canSubmit && !busy, submitting: busy, submitLabel: "Force unstake", onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                await forceUnstakeFromSponsorship(chainId, selectedSponsorshipId, operator.id, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                onResolve?.(selectedSponsorshipId);
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(Root, null,
            React.createElement(SectionHeadline, null, "Because there are expired items in the undelegation queue, you can force the Operator to unstake from any Sponsorship, in order to pay out the queued undelegations."),
            React.createElement(TableWrap, null,
                React.createElement(ScrollTable, { elements: stakes, columns: [
                        {
                            displayName: 'Stream ID',
                            valueMapper: ({ streamId }) => (React.createElement(StreamIdCell, { streamId: streamId })),
                            align: 'start',
                            isSticky: true,
                            key: 'streamid',
                        },
                        {
                            displayName: 'Amount',
                            valueMapper: (element) => (React.createElement(WarningCell, null,
                                React.createElement(Decimals, { abbr: true, amount: element.amountWei, decimals: decimals, tooltip: true }),
                                element.amountWei < amount && (React.createElement(Tooltip, { content: React.createElement("p", null, "Partial payout") },
                                    React.createElement(TooltipIconWrap, { "$color": "#ff5c00" },
                                        React.createElement(JiraFailedBuildStatusIcon, { label: "Error" })))))),
                            align: 'start',
                            isSticky: false,
                            key: 'amount',
                        },
                        {
                            displayName: 'Joined',
                            valueMapper: (element) => (React.createElement(WarningCell, null,
                                moment(element.joinedAt).format('YYYY-MM-DD'),
                                !isStakedLongEnough(element.joinedAt, element.minimumStakingPeriodSeconds) && (React.createElement(Tooltip, { content: React.createElement("p", null,
                                        "Minimum stake period of",
                                        ' ',
                                        element.minimumStakingPeriodSeconds /
                                            60 /
                                            60 /
                                            24,
                                        ' ',
                                        "days not reached. Operator will be slashed.") },
                                    React.createElement(TooltipIconWrap, { "$color": "#ff5c00" },
                                        React.createElement(JiraFailedBuildStatusIcon, { label: "Error" })))))),
                            align: 'start',
                            isSticky: false,
                            key: 'joined',
                        },
                        {
                            displayName: '',
                            valueMapper: ({ sponsorshipId: id }) => (React.createElement(React.Fragment, null,
                                React.createElement(Radio, { name: "undelegate-sponsorship", id: `undelegate-sponsorship-${id}`, label: "", value: id, checked: selectedSponsorshipId === id, onChange: (value) => {
                                        setSelectedSponsorshipId(value);
                                    } }))),
                            align: 'end',
                            isSticky: false,
                            key: 'actions',
                        },
                    ] })),
            willSlash && (React.createElement(Alert, { type: "error", title: "Operator will be slashed" }, "Selecting this Sponsorship will result in slashing. Please consider selecting a different Sponsorship if available.")),
            isPartialPayout && (React.createElement(Alert, { type: "error", title: "Partial payout" }, "Unstaking from this Sponsorship will not completely cover the queued undelegation. After unstaking, you can select additional Sponsorships to continue payouts.")))));
}
export const forceUndelegateModal = toaster(ForceUndelegateModal, Layer.Modal);
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-pbscr5" }) `
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 16px;
`;
const TableWrap = styled.div.withConfig({ displayName: "TableWrap", componentId: "sc-1ba6jjt" }) `
    max-height: ${90 * 6}px;
    overflow: auto;
`;
const ForceUndelegateFormModal = styled(FormModal).withConfig({ displayName: "ForceUndelegateFormModal", componentId: "sc-xoyuyl" }) `
    & ${FormModalRoot} {
        max-width: 848px;
    }
`;
const WarningCell = styled.div.withConfig({ displayName: "WarningCell", componentId: "sc-1gwdkfm" }) `
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto;

    ${TooltipIconWrap} svg {
        width: 18px;
        height: 18px;
    }
`;
