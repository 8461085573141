import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { DisclaimerBar } from '~/components/DisclaimerBar';
import Footer from '~/components/Footer';
import Helmet from '~/components/Helmet';
import Nav from '~/components/Nav';
import useScrollToTop from '~/shared/hooks/useScrollToTop';
import { MAX_BODY_WIDTH } from '~/shared/utils/styled';
export default function Layout({ children, footer = React.createElement(Footer, null), nav = React.createElement(Nav, null), columnize = false, pageTitle, innerBackgroundColor, outerBackgroundColor, rootBackgroundColor = '#f5f5f5', rootComponent: Root = LayoutRoot, outerComponent: Outer = LayoutOuter, innerComponent: Inner = LayoutInner, }) {
    useScrollToTop();
    return (React.createElement(React.Fragment, null,
        pageTitle && React.createElement(Helmet, { title: pageTitle }),
        React.createElement(GlobalStyles, null),
        React.createElement(Root, { "$bg": rootBackgroundColor },
            React.createElement(Outer, { "$bg": outerBackgroundColor },
                React.createElement(DisclaimerBar, null),
                nav,
                React.createElement(Inner, { "$bg": innerBackgroundColor }, columnize ? React.createElement(LayoutColumn, null, children) : children)),
            footer)));
}
const LayoutRoot = styled.main.withConfig({ displayName: "LayoutRoot", componentId: "sc-1i86ztv" }) `
    background-color: ${({ $bg }) => $bg};
    color: #323232;
`;
const LayoutOuter = styled.div.withConfig({ displayName: "LayoutOuter", componentId: "sc-1odzq7a" }) `
    background-color: ${({ $bg }) => $bg};
    display: flex;
    flex-direction: column;
    font-size: 16px;
    min-height: 100vh;
    position: relative;
    z-index: 1;
`;
export const LayoutInner = styled.div.withConfig({ displayName: "LayoutInner", componentId: "sc-vybfwz" }) `
    background-color: ${({ $bg }) => $bg};
`;
const GlobalStyles = createGlobalStyle `
    body {
        font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
        font-size: 16px;
        line-height: 2em;
        -webkit-overflow-scrolling: touch;
    }

    input {
        font-family: inherit;
    }

    body a {
        text-decoration: none;
    }

    p a {
        text-decoration: underline;
    }

    strong {
        font-weight: 500;
    }

    a {
        color: #0324FF;
        font-weight: inherit;
    }

    a:link,
    a:active,
    a:focus,
    a:hover,
    a:visited {
        text-decoration: none;
    }

    a:focus,
    a:hover {
        color: #0D009A;
    }

    a:active {
        color: #13013D;
    }
`;
export const LayoutColumn = styled.div.withConfig({ displayName: "LayoutColumn", componentId: "sc-3b9tnl" }) `
    margin: 0 auto;
    max-width: ${MAX_BODY_WIDTH}px;
    padding: 0 24px;

    @media (min-width: ${MAX_BODY_WIDTH + 48}px) {
        padding: 0;
    }
`;
