export class Parsable {
    constructor(raw, chainId) {
        this.raw = raw;
        this.chainId = chainId;
        this.cache = {};
        this.preparsed = {};
        this.preparsed = this.preparse();
    }
    preparse() {
        throw new Error('Not implemented');
    }
    getValue(key, parser) {
        const cachedValue = this.cache[key];
        if (key in this.cache) {
            return cachedValue;
        }
        const value = parser ? parser(this.preparsed[key]) : this.preparsed[key];
        this.cache[key] = value;
        return value;
    }
}
