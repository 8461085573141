import React from 'react';
import styled from 'styled-components';
import { SponsorshipDecimals } from '~/components/Decimals';
import { Hint } from '~/components/Hint';
import { LayoutColumn } from '~/components/Layout';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { StatCell, StatGrid } from '~/components/StatGrid';
import { DayInSeconds } from '~/consts';
import { COLORS } from '~/shared/utils/styled';
export function SponsorshipSummary({ sponsorship }) {
    const minimumStakingDays = sponsorship.minimumStakingPeriodSeconds / DayInSeconds;
    return (React.createElement(SponsorshipSummaryRoot, null,
        React.createElement(LayoutColumn, null,
            React.createElement(StatGrid, { "$desktopColumnCount": 3 },
                React.createElement(StatCell, { label: "Payout rate", tip: React.createElement(Hint, null,
                        React.createElement("p", null,
                            "The rate of ",
                            React.createElement(SponsorshipPaymentTokenName, null),
                            " tokens that are distributed to Operators that have staked on this Sponsorship.")) },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: sponsorship.payoutPerDay, unitSuffix: "/day" })),
                React.createElement(StatCell, { label: "Remaining balance" },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: sponsorship.timeCorrectedRemainingBalanceWeiAt(Date.now()) })),
                React.createElement(StatCell, { label: "Total staked", tip: React.createElement(Hint, null,
                        React.createElement("p", null,
                            "The total amount of ",
                            React.createElement(SponsorshipPaymentTokenName, null),
                            ' ',
                            "tokens that has been staked on this Sponsorship by\u00A0Operators.")) },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: sponsorship.totalStakedWei })),
                React.createElement(StatCell, { label: "APY", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The annualized yield that the staked Operators are currently earning from this Sponsorship.")) },
                    (sponsorship.spotApy * 100).toFixed(0),
                    "%"),
                React.createElement(StatCell, { label: "Total sponsored", tip: React.createElement(Hint, null,
                        React.createElement("p", null,
                            "The cumulative amount of",
                            ' ',
                            React.createElement(SponsorshipPaymentTokenName, null),
                            " tokens that Sponsors have funded this Sponsorship with.")) },
                    React.createElement(SponsorshipDecimals, { abbr: true, amount: sponsorship.cumulativeSponsoring })),
                React.createElement(StatCell, { label: "Minimum stake duration", tip: React.createElement(Hint, null,
                        React.createElement("p", null, "The minimum time that Operators must stay staked in this Sponsorship before they are able to fully unstake without a penalty. Stake reduction is always allowed and only limited by minimum\u00A0stake.")) },
                    minimumStakingDays.toFixed(0),
                    " day",
                    minimumStakingDays !== 1 && 's')))));
}
const SponsorshipSummaryRoot = styled.div.withConfig({ displayName: "SponsorshipSummaryRoot", componentId: "sc-4rika8" }) `
    background: ${COLORS.Background};
    padding: 24px 0;
`;
