import moment from 'moment';
import React, { useMemo } from 'react';
import { AboutSponsorship } from '~/components/ActionBars/AboutSponsorship';
import { ActionBarButton, ActionBarButtonBody, ActionBarButtonCaret, ActionBarButtonInnerBody, ActionBarWalletDisplay, } from '~/components/ActionBars/ActionBarButton';
import { Button } from '~/components/Button';
import { SimpleDropdown } from '~/components/SimpleDropdown';
import { Tooltip } from '~/components/Tooltip';
import { useOperatorForWalletQuery } from '~/hooks/operators';
import { useEditSponsorshipFunding, useFundSponsorshipCallback, useIsEditingSponsorshipFunding, useIsFundingSponsorship, useIsJoiningSponsorshipAsOperator, useJoinSponsorshipAsOperator, } from '~/hooks/sponsorships';
import SvgIcon from '~/shared/components/SvgIcon';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS } from '~/shared/utils/styled';
import { truncate, truncateStreamName } from '~/shared/utils/text';
import { useCurrentChainId, useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
import { isSponsorshipFundedByOperator } from '~/utils/sponsorships';
import { AbstractActionBar } from './AbstractActionBar';
export function SponsorshipActionBar({ sponsorship }) {
    const wallet = useWalletAccount();
    const { data: operator = null } = useOperatorForWalletQuery(wallet);
    const canEditStake = isSponsorshipFundedByOperator(sponsorship, operator);
    const { projectedInsolvencyAt, isRunning } = sponsorship;
    const isPaying = isRunning && sponsorship.timeCorrectedRemainingBalanceWeiAt(Date.now()) > 0n;
    const [fundedUntil, detailedFundedUntil] = useMemo(() => projectedInsolvencyAt == null
        ? [null, null]
        : [
            moment(projectedInsolvencyAt).format('D MMM YYYY'),
            moment(projectedInsolvencyAt).format('D MMM YYYY, HH:mm'),
        ], [projectedInsolvencyAt]);
    const fundSponsorship = useFundSponsorshipCallback();
    const isFundingSponsorship = useIsFundingSponsorship(sponsorship.id, wallet);
    const editSponsorshipFunding = useEditSponsorshipFunding();
    const isEditingSponsorshipFunding = useIsEditingSponsorshipFunding(sponsorship.id, operator?.id);
    const { streamId } = sponsorship;
    const chainId = useCurrentChainId();
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(AbstractActionBar, { fallbackBackButtonUrl: R.sponsorships(routeOptions(chainName)), title: streamId ? (truncateStreamName(streamId, 30)) : (React.createElement(React.Fragment, null,
            "Sponsorship ",
            truncate(sponsorship.id))), buttons: React.createElement(React.Fragment, null,
            React.createElement(ActionBarButtonBody, { "$background": isPaying ? COLORS.activeBackground : COLORS.radioBorder, "$color": isPaying ? COLORS.active : COLORS.primary },
                React.createElement("strong", null, isPaying ? 'Paying' : 'Inactive')),
            React.createElement(SimpleDropdown, { menu: React.createElement(AboutSponsorship, { sponsorship: sponsorship }) }, (toggle, isOpen) => (React.createElement(ActionBarButton, { active: isOpen, onClick: () => void toggle((c) => !c) },
                React.createElement(ActionBarButtonInnerBody, null,
                    React.createElement(SvgIcon, { name: "page" }),
                    React.createElement("strong", null, "About Sponsorship")),
                React.createElement(ActionBarButtonCaret, { "$invert": isOpen })))),
            fundedUntil && (React.createElement(ActionBarButtonBody, null,
                React.createElement("div", null,
                    "Funded until:",
                    ' ',
                    React.createElement("strong", null,
                        React.createElement(Tooltip, { anchorDisplay: "inline", content: detailedFundedUntil || '' }, fundedUntil))))),
            React.createElement(ActionBarWalletDisplay, { address: sponsorship.id, label: "Contract" })), ctas: React.createElement(React.Fragment, null,
            canEditStake ? (React.createElement(Button, { disabled: !operator, waiting: isEditingSponsorshipFunding, onClick: async () => {
                    if (!operator) {
                        return;
                    }
                    editSponsorshipFunding({
                        chainId,
                        sponsorshipOrSponsorshipId: sponsorship,
                        operator,
                    });
                } }, "Edit stake")) : (React.createElement(JoinAsOperatorButton, { sponsorship: sponsorship, operator: operator })),
            React.createElement(Button
            // We decided to disable sponsoring for now as users don't know what it does.
            // https://streamr-team.slack.com/archives/C9QB9RJ48/p1701774490263629
            , { 
                // We decided to disable sponsoring for now as users don't know what it does.
                // https://streamr-team.slack.com/archives/C9QB9RJ48/p1701774490263629
                disabled: !streamId || true, kind: "secondary", waiting: isFundingSponsorship, onClick: () => {
                    fundSponsorship(chainId, sponsorship);
                } }, "Sponsor")) }));
}
function JoinAsOperatorButton({ sponsorship, operator }) {
    const walletLocked = !useWalletAccount();
    const { streamId, operatorCount, maxOperators } = sponsorship;
    const isJoiningSponsorshipAsOperator = useIsJoiningSponsorshipAsOperator(sponsorship.id, operator?.id);
    const chainId = useCurrentChainId();
    const joinSponsorshipAsOperator = useJoinSponsorshipAsOperator();
    const maxOperatorsReached = operatorCount >= maxOperators;
    const tip = walletLocked ? ('Unlock your wallet first') : !operator ? ('You need an Operator to join a Sponsorship') : !streamId ? ('Sponsored stream does not exist') : maxOperatorsReached ? (React.createElement(React.Fragment, null, "This Sponsorship does not allow more\u00A0Operators")) : undefined;
    if (tip) {
        return (React.createElement(Tooltip, { content: tip },
            React.createElement(Button, { disabled: true }, "Join as operator")));
    }
    return (React.createElement(Button, { waiting: isJoiningSponsorshipAsOperator, onClick: () => {
            if (!operator) {
                return;
            }
            joinSponsorshipAsOperator({
                chainId,
                sponsorship,
                operator,
            });
        } }, "Join as operator"));
}
