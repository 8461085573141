import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from '~/components/Alert';
import { useOperatorForWalletQuery } from '~/hooks/operators';
import { useJoinSponsorshipAsOperator } from '~/hooks/sponsorships';
import Checkbox from '~/shared/components/Checkbox';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS } from '~/shared/utils/styled';
import { useCurrentChainId } from '~/utils/chains';
export function SponsorshipDisclaimer({ sponsorship, onResolve, checkboxState, onCheckboxStateChange, }) {
    const chainId = useCurrentChainId();
    const joinSponsorshipAsOperator = useJoinSponsorshipAsOperator();
    const wallet = useWalletAccount();
    const { data: operator = null } = useOperatorForWalletQuery(wallet);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledAlert, { type: "error", title: "Warning" },
            "To earn, Operators should",
            ' ',
            sponsorship == null ? ('join Sponsorships') : (React.createElement(Link, { to: "#", onClick: () => {
                    if (operator == null) {
                        return;
                    }
                    // Close this modal
                    onResolve?.();
                    // Open join sponsorship modal
                    joinSponsorshipAsOperator({
                        chainId,
                        sponsorship,
                        operator,
                    });
                } }, "join Sponsorships")),
            ", rather than fund/sponsor them. This action cannot be undone."),
        React.createElement(Confirm, null,
            React.createElement(Checkbox, { id: "fund-sponsorship-checkbox", value: checkboxState, onChange: (e) => {
                    onCheckboxStateChange(e.target.checked);
                } }),
            React.createElement("label", { htmlFor: "fund-sponsorship-checkbox" }, "I understand this disclaimer and I know what I'm doing."))));
}
const StyledAlert = styled(Alert).withConfig({ displayName: "StyledAlert", componentId: "sc-yl9km5" }) `
    margin-top: 16px;

    a {
        color: ${COLORS.link};
    }
`;
const Confirm = styled.div.withConfig({ displayName: "Confirm", componentId: "sc-qv0c1p" }) `
    display: flex;
    align-items: center;
    margin-top: 8px;

    & label {
        margin-bottom: 0;
    }
`;
