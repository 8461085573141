import '@ibm/plex/css/ibm-plex.css';
import { NavProvider } from '@streamr/streamr-layout';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createBrowserRouter, Navigate, Route, RouterProvider, Routes, } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from 'toasterhea';
import '~/analytics';
import GenericErrorPage from '~/pages/GenericErrorPage';
import { NetworkOverviewPage } from '~/pages/NetworkOverviewPage';
import NotFoundPage from '~/pages/NotFoundPage';
import { OperatorPage } from '~/pages/OperatorPage';
import { OperatorsPage } from '~/pages/OperatorsPage';
import ProjectListingPage from '~/pages/ProjectListingPage';
import { ExistingProjectPageWrap, NewProjectPage, ProjectConnectPage, ProjectDraftPage, ProjectIndexRedirect, ProjectLiveDataPage, ProjectOverviewPage, ProjectTabbedPage, } from '~/pages/ProjectPage';
import { SingleSponsorshipPage } from '~/pages/SingleSponsorshipPage';
import { SponsorshipsPage } from '~/pages/SponsorshipsPage';
import { StreamConnectPage, StreamDraftPage, StreamEditPage, StreamIndexRedirect, StreamLiveDataPage, StreamTabbedPage, } from '~/pages/StreamPage';
import { StreamsPage } from '~/pages/StreamsPage';
import '~/shared/assets/stylesheets';
import Globals from '~/shared/components/Globals';
import StreamrClientProvider from '~/shared/components/StreamrClientProvider';
import { Provider as ModalProvider } from '~/shared/contexts/ModalApi';
import { Provider as ModalPortalProvider } from '~/shared/contexts/ModalPortal';
import { getQueryClient } from '~/utils';
import { Layer } from '~/utils/Layer';
import { Route as R } from '~/utils/routes';
import '~/utils/setupSnippets';
import ProjectEditorPage from './pages/ProjectPage/ProjectEditorPage';
const Root = () => (React.createElement(Providers, null,
    React.createElement(Globals, null),
    React.createElement(Routes, null,
        React.createElement(Route, { errorElement: React.createElement(GenericErrorPage, null) },
            React.createElement(Route, { path: "/hub/projects" },
                React.createElement(Route, { index: true, element: React.createElement(ProjectListingPage, null) }),
                React.createElement(Route, { element: React.createElement(ProjectDraftPage, null) },
                    React.createElement(Route, { path: "new", element: React.createElement(NewProjectPage, null) }),
                    React.createElement(Route, { path: ":id", element: React.createElement(ExistingProjectPageWrap, null) },
                        React.createElement(Route, { index: true, element: React.createElement(ProjectIndexRedirect, null) }),
                        React.createElement(Route, { path: "edit", element: React.createElement(ProjectEditorPage, null) }),
                        React.createElement(Route, { element: React.createElement(ProjectTabbedPage, null) },
                            React.createElement(Route, { path: "overview", element: React.createElement(ProjectOverviewPage, null) }),
                            React.createElement(Route, { path: "connect", element: React.createElement(ProjectConnectPage, null) }),
                            React.createElement(Route, { path: "live-data", element: React.createElement(ProjectLiveDataPage, null) }))))),
            React.createElement(Route, { path: "/hub/streams" },
                React.createElement(Route, { index: true, element: React.createElement(StreamsPage, null) }),
                React.createElement(Route, { element: React.createElement(StreamDraftPage, null) },
                    React.createElement(Route, { path: "new", element: React.createElement(StreamTabbedPage, { stickySubmit: true }, (attach, ready) => ready ? (React.createElement(StreamEditPage, { saveButtonRef: attach })) : null) }),
                    React.createElement(Route, { path: ":id" },
                        React.createElement(Route, { index: true, element: React.createElement(StreamIndexRedirect, null) }),
                        React.createElement(Route, { path: "overview", element: React.createElement(StreamTabbedPage, { stickySubmit: true }, (attach, ready) => ready ? (React.createElement(StreamEditPage, { saveButtonRef: attach })) : null) }),
                        React.createElement(Route, { element: React.createElement(StreamTabbedPage, null) },
                            React.createElement(Route, { path: "connect", element: React.createElement(StreamConnectPage, null) }),
                            React.createElement(Route, { path: "live-data", element: React.createElement(StreamLiveDataPage, null) }))))),
            React.createElement(Route, { path: "/hub/network" },
                React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: {
                            pathname: R.sponsorships(),
                            search: window.location.search,
                        }, replace: true }) }),
                React.createElement(Route, { path: "operators" },
                    React.createElement(Route, { index: true, element: React.createElement(OperatorsPage, null) }),
                    React.createElement(Route, { path: ":id", element: React.createElement(OperatorPage, null) })),
                React.createElement(Route, { path: "sponsorships" },
                    React.createElement(Route, { index: true, element: React.createElement(SponsorshipsPage, null) }),
                    React.createElement(Route, { path: ":id", element: React.createElement(SingleSponsorshipPage, null) })),
                React.createElement(Route, { path: "overview", element: React.createElement(NetworkOverviewPage, null) })),
            React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: R.projects(), replace: true }) }),
            React.createElement(Route, { path: "/hub", element: React.createElement(Navigate, { to: {
                        pathname: R.projects(),
                        search: window.location.search,
                    }, replace: true }) }),
            React.createElement(Route, { path: "/error", element: React.createElement(GenericErrorPage, null) }),
            React.createElement(Route, { path: "*", element: React.createElement(NotFoundPage, null) }),
            ",")),
    React.createElement(ModalContainer, { id: Layer.Modal }),
    React.createElement(ToastContainer, { id: Layer.Toast })));
export default App;
const ModalContainer = styled(Container).withConfig({ displayName: "ModalContainer", componentId: "sc-1yzkrdj" }) `
    position: relative;
    z-index: 11;
`;
const ToastContainer = styled(Container).withConfig({ displayName: "ToastContainer", componentId: "sc-oqfhqp" }) `
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-bottom: 24px;
    padding-right: 24px;
    position: fixed;
    z-index: 12;
`;
function Providers({ children }) {
    return (React.createElement(NavProvider, null,
        React.createElement(StreamrClientProvider, null,
            React.createElement(ModalPortalProvider, null,
                React.createElement(ModalProvider, null, children)))));
}
const router = createBrowserRouter([
    { path: '*', element: React.createElement(Root, null), errorElement: React.createElement(GenericErrorPage, null) },
]);
function App() {
    return (React.createElement(QueryClientProvider, { client: getQueryClient() },
        React.createElement(RouterProvider, { router: router })));
}
